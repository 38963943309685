// Import Fuse core library
@import '@fuse/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
// Global vendors
// @import '~bootstrap/dist/css/bootstrap.css';
// => Material
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import 'highlight.js/styles/googlecode.css';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

// Global fonts
@import './assets/plugins/flaticon/flaticon.css';
@import './assets/plugins/flaticon2/flaticon.css';
@import './assets/plugins/keenthemes-icons/font/ki.css';

// Metronic styles (replace these path when using RTL css below)
@import './assets/sass/style.angular.scss';

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

snack-bar-container.error {
  max-width: 45vw !important;
  padding: 20px;
  background-color: #f46036 !important;
  color: white !important;
  .mat-simple-snackbar-action {
    color: pink !important;
  }
}

snack-bar-container.error-rtl {
  direction: rtl;
  max-width: 45vw !important;
  padding: 20px;
  background-color: #f46036 !important;
  color: white !important;
  .mat-simple-snackbar-action {
    color: pink !important;
  }
}

snack-bar-container.success {
  padding: 20px;
  background-color: #4fcaa3 !important;
  color: #eaf6f2 !important;
}

snack-bar-container.success-rtl {
  direction: rtl;
  padding: 20px;
  background-color: #4fcaa3 !important;
  color: #eaf6f2 !important;
}

snack-bar-container.download {
  padding: 20px;
  background-color: #4fcaa3 !important;
  color: #eaf6f2 !important;
}

snack-bar-container.download-rtl {
  direction: rtl;
  padding: 20px;
  background-color: #4fcaa3 !important;
  color: #eaf6f2 !important;
}

snack-bar-container.success-100 {
  padding: 20px;
  background-color: #28a745 !important;
  color: #fff !important;
}

snack-bar-container.success-100-rtl {
  direction: rtl;
  padding: 20px;
  background-color: #28a745 !important;
  color: #fff !important;
}

.tooltip-new-liine {
  white-space: pre-line !important;
}

.fuse-navy-700 {
  background-color: #21283e !important;
  color: white !important;
}

.fuse-navy-900 {
  background-color: #0b132b !important;
  color: white !important;
}

.timepicker {
  .timepicker__header {
    background-color: #4fcaa3 !important;
  }

  .clock-face__clock-hand {
    background-color: #4fcaa3 !important;

    &::after {
      background-color: #4fcaa3 !important;
    }

    &::before {
      border-color: #4fcaa3 !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: #4fcaa3 !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: #4fcaa3 !important;
  }
}

.mat-menu-panel {
  max-width: 320px !important;
}

* {
  font-family: 'Lato', 'arial', sans-serif !important;
}

img {
  border-style: none;
}
