@use '@angular/material' as mat;
@mixin board-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  mat.$green-palette: map-get(
    $map: $theme,
    $key: accent,
  );
  mat.$red-palette: map-get(
    $map: $theme,
    $key: primary,
  );

  // List
  project-board-list {
    .list {
      .list-header,
      .list-footer {
        border-radius: 0.5em;
        background-color: white !important;
      }

      .list-content {
        background-color: white !important;
      }

      .list-header {
        border-bottom-color: map-get($foreground, divider);
      }

      .list-footer {
        border-top-color: map-get($foreground, divider);
      }
    }
  }

  // Add list
  project-board-add-list {
    .new-list {
      background: map-get($background, app-bar);

      .new-list-form {
        > input {
          background: map-get($background, app-bar);
          color: map-get($foreground, text);
        }
      }
    }
  }

  // Add card directive
  project-board-add-card {
    .add-card-button {
      background-color: map-get($background, app-bar);
    }

    .add-card-form-wrapper {
      background-color: map-get($background, app-bar);
    }
  }

  // Card
  project-board-card {
    background-color: map-get($background, card);
    //border-color: map-get($foreground, divider);

    .list-card-details {
      .list-card-badges {
        .badge {
          background-color: rgba(0, 0, 0, 0.4);
          color: #ffffff;

          &.due-date {
            background-color: rgb(172, 172, 172);

            &.overdue {
              background-color: red !important;
            }

            &.warning {
              background-color: orange;
            }
          }

          &.check-items {
            &.completed {
              background-color: greenyellow;
            }
          }
        }
      }
    }

    .list-card-footer {
      border-top-color: map-get($foreground, divider);

      .list-card-footer-item {
        color: map-get($foreground, secondary-text);
      }
    }
  }

  // Card dialog
  .project-board-card-dialog {
    .mat-dialog-container {
      .mat-dialog-content {
        .sections {
          .section {
            border-bottom-color: map-get($foreground, divider);

            .attachments {
              .attachment {
                .attachment-preview {
                  background-color: map-get($background, card);
                }

                .attachment-content {
                  .attachment-time {
                    color: map-get($foreground, secondary-text);
                  }
                }
              }
            }

            .comments {
              .comment {
                .comment-bubble {
                  background-color: map-get($background, app-bar);
                  border-color: map-get($background, app-bar);

                  &:after,
                  &:before {
                    border-color: map-get($background, app-bar) transparent
                      transparent transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .project-board-labels-menu {
    .mat-menu-content {
      .views {
        .view {
          > .header {
            border-bottom-color: map-get($foreground, divider);
          }
        }
      }
    }
  }

  // Settings
  project-board-settings {
    .views {
      .view {
        > .header {
          border-bottom-color: map-get($foreground, divider);
          background: map-get($accent, default);
          color: map-get($accent, default-contrast);
        }
      }
    }
  }
}
