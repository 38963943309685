@use '@angular/material' as mat;
@mixin welcome-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  access-welcome {
    #welcome {
      #welcome-form-wrapper {
        #welcome-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .subtitle {
            color: map-get($foreground, secondary-text);
          }
        }
      }
    }
  }
}
